<!-- <template>
  <div class="ad-section">
    <h1>Advertise with Campit - Boost Your Camp's Reach!</h1>
    
    <div class="section-break"></div> 

    <div class="advertise-section">
      <h2>Why Advertise with Campit?</h2>
      <div class="for-camps">
        <h3>For Camps:</h3>
        <ul>
          <li>Increase Visibility: Reach out to a wide audience...</li>
          <li>Free Listings: Get your camp noticed with detailed descriptions...</li>
          <li>Affordable Advertising: Tailored for smaller budgets...</li>
        </ul>
      </div>
    </div>

    <div class="section-break"></div> 

    <div class="advertising-options">
      <h2>Advertising Options:</h2>
      <div class="ad-option">
        <h3>Sidebar Block Ads:</h3>
        <p>Get noticed on every page! Place your camp...</p>
        <p>Dimensions: 300x250 pixels</p>
        <p>Cost: $30/month (Minimum 3 months)</p>
      </div>
    </div>

    <div class="section-break"></div> 

    <div class="special-offer">
      <h2>Special Offer: Annual Ads</h2>
      <p>Unlock huge savings! Sign up for an annual ad...</p>
    </div>

    <div class="section-break"></div> 

    <div class="get-started">
      <h2>How to Get Started?</h2>
      <ul>
        <li>Create Your Camp Profile: Start by listing your camp for free.</li>
        <li>Choose Your Advertising Package: Pick the ad spaces...</li>
      </ul>
    </div>

    <div class="section-break"></div> 

    <div class="contact-section">
      <h2>Ready to Advertise?</h2>
      <p>Ready to boost your camp's visibility? Contact us today...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampitAdvertise'
  // Your script here
};
</script>

<style scoped>
.ad-section {
  background-color: #689F38;
  padding: 20px;
  color: white; 
}

.section-break {
  height: 20px;
  clear: both;
}


</style> -->

<template>
  <div class="home fullPageBackground">
    <Navbar></Navbar>
    <v-container fluid class="textCenter">
      <v-row no-gutters class="backgroundGreen">
        <h2>Advertise with Campit - Boost Your Camp's Reach!</h2>
      </v-row>
      <v-row no-gutters class="backgroundGreen">
        <h3>Contact Us: info@campit.app</h3>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'Ads',
  components: {
    Navbar: Navbar
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.container {
  min-height: 100vh; /* Make the .home element cover the full viewport height */
  background-color: #689F38;
}
.fullPageBackground {
  display: flex;
  flex-direction: column;
}



.backgroundGreen h2, .backgroundGreen h3 {
  margin-left: auto;
  margin-right: auto;
  
}
.backgroundGreen {
  width: 100%;
  padding: 25px 0;
  margin: 0 auto; /* Center the row */
}

/* If you need to make the background cover the whole page, consider adding this in your global styles (not scoped) */
body {
  background-color: #689F38;
}
</style>